import React from "react";
import { connect } from "react-redux";
import LoadingAnimation from "../../../components/LoadingAnimation";
import config from "../../../config";
import { GenerateReportResponse } from "../../../interfaces/report";
import ReportService from "../../../services/Report";
import { Mixpanel } from "../../../utils/analytics";
import { postFormErrorLabel } from "../../../utils/errors";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
  logUpdatedState,
} from "../../../utils/loggers";
import { displayToastMessage } from "../../../utils/toasts";
import { jsPDF } from "jspdf";
import "./index.css";
import PlanDetails from "../../../components/PlanDetails";
import AdsManager from "../../../components/AdsManager";
import PaymentService from "../../../services/Payment";
import { JoinProPlanResponse } from "../../../interfaces/payment";
import HistoryService from "../../../services/History";
import { ReviewPlanResponse } from "../../../interfaces/history";

class Planner extends React.Component<any, any> {
  HistoryService: HistoryService;
  PaymentService: PaymentService;
  ReportService: ReportService;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      currentView: props.currentView ? props.currentView : "form", // form || summary || historicalSummary
      reportGenerationForm: {
        userID: props.user.id,
        activityType: "Dining",
        statusType: "Couples",
        location: "San Francisco",
        totalDays: 1,
        totalOptions: 1,
        budget: 200,
        currency: "USD",
        promoCode: "",
      },
      locationImage: process.env.PUBLIC_URL + "/report-cover-image.webp",
      planData: props.planData ? props.planData : null,
    };

    this.HistoryService = new HistoryService();
    this.PaymentService = new PaymentService();
    this.ReportService = new ReportService();

    this.updateFormData = this.updateFormData.bind(this);
    this.renderAds = this.renderAds.bind(this);
    this.renderGenerationForm = this.renderGenerationForm.bind(this);
    this.renderPlannerActionButtons =
      this.renderPlannerActionButtons.bind(this);
    this.handleJoinProPlan = this.handleJoinProPlan.bind(this);
    this.generateReport = this.generateReport.bind(this);
    this.handleReviewPlan = this.handleReviewPlan.bind(this);
    this.renderPlanDetails = this.renderPlanDetails.bind(this);
    this.renderDownloadButtons = this.renderDownloadButtons.bind(this);
    this.handleDownloadPDF = this.handleDownloadPDF.bind(this);
    this.handleDownloadICal = this.handleDownloadICal.bind(this);
  }

  renderHeader() {
    return (
      <header className="planner-header">
        <h1 className="common-home-content-header-label">Planner</h1>
        <p className="ad-inquiry-text">
          Want your ad here? In recommendations? <br />
          <a href="mailto:sales@gptmyday.com?subject=Ad%20Placement%20Inquiry">
            Contact Us
          </a>
        </p>
        {this.renderAds()}
        {this.renderDownloadButtons()}
      </header>
    );
  }

  renderAds() {
    if (!this.props.user.subscribed) {
      // Banner and/or video ad
      return (
        <div style={{ width: "100%" }}>
          <AdsManager />
          <br />
          <a href="#subscribe" onClick={this.handleJoinProPlan}>
            Click here to remove ads!
          </a>
        </div>
      );
    }
  }

  async handleDownloadPDF(e: Event | any) {
    e.preventDefault();

    if (!this.props.user.subscribed) {
      await this.handleJoinProPlan();
    }

    displayToastMessage("info", "Downloading...");

    Mixpanel.track("Download PDF", {});

    // Default export is a3 paper, portrait, using millimeters for units
    const pageFormat = "a3";
    const doc = new jsPDF({
      format: pageFormat,
    });

    let y = 10;

    doc.text("GPTMyDay Plan", 5, y);

    y += 10;

    this.state.planData.recommendations.forEach((r: any, i: number) => {
      doc.text(`Day #${r.dayNumber}`, 5, y);

      y += 15;

      r.activities.forEach((activity: any, i: number) => {
        doc.text(`Recommendation #${i}`, 5, y);

        y += 10;

        Object.entries(activity).forEach(([key, value]: any) => {
          if (key === "recommendedStartTime" || key === "recommendedEndTime") {
            value = new Date(value).toLocaleString();
          }
          doc.text(`${key}: ${value}`, 5, y);
          y += 10;
        });

        y += 5;
      });

      doc.addPage(pageFormat, "p");

      y = 10;
    });

    doc.text(`Total Cost: ${this.state.planData.totalExpense}`, 5, y);

    doc.save("plan.pdf");

    displayToastMessage("success", "Success");
  }

  async handleDownloadICal(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleDownloadICal");

      if (!this.props.user.subscribed) {
        await this.handleJoinProPlan();
      }

      displayToastMessage("info", "Downloading...");

      Mixpanel.track("Download ICal", {});

      const sticResBuffer: any = await this.ReportService.syncToICal({
        userID: this.props.user.id,
        planHistoryID: this.state.planData.planHistoryID,
      });
      const blob = new Blob([sticResBuffer]);
      const url = window.URL.createObjectURL(blob);
      const a: any = document.createElement("a");

      document.body.appendChild(a);

      a.style = "display: none";
      a.href = url;
      a.download = "plan.ics";

      a.click();

      window.URL.revokeObjectURL(url);

      displayToastMessage("success", "Success");
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleDownloadICal", error);
      displayToastMessage("error", postFormErrorLabel("Download ICal File"));
    }
  }

  renderDownloadButtons() {
    if (this.state.planData !== null) {
      return (
        <div className="download-buttons-row row">
          <button
            className="common-update-form-button summary-download-button"
            onClick={this.handleDownloadPDF}
          >
            PDF
          </button>
          <button
            className="common-update-form-button summary-download-button"
            onClick={this.handleDownloadICal}
          >
            iCAL
          </button>
        </div>
      );
    }
  }

  updateFormData(evt: Event | any, formLabel: string, formKeyLabel: string) {
    this.setState(
      {
        [formLabel]: {
          ...this.state[formLabel],
          [formKeyLabel]: evt.target.value,
        },
      },
      () => logUpdatedState(this.state, formLabel)
    );
  }

  renderGenerationForm() {
    if (this.state.currentView === "form") {
      if (this.state.loading) {
        return <LoadingAnimation />;
      }

      let totalDaysThreshold = 7;
      let totalOptionsThreshold = 7;
      if (!this.props.user.subscribed) {
        totalDaysThreshold = 1;
        totalOptionsThreshold = 1;
      }

      return (
        <div className="report-content-container">
          <form>
            <div className="label-input-container">
              <label>Activity Type*:</label>
              <select
                className="pp-form-select form-select"
                placeholder="Activity Type*"
                value={this.state.reportGenerationForm.activityType}
                onChange={(evt) =>
                  this.updateFormData(
                    evt,
                    "reportGenerationForm",
                    "activityType"
                  )
                }
              >
                {config.reports.activityTypes.map((activityType, i: number) => (
                  <option key={i} value={activityType}>
                    {activityType}
                  </option>
                ))}
              </select>
            </div>
            <div className="label-input-container">
              <label>Status*:</label>
              <select
                className="pp-form-select form-select"
                placeholder="Activity Type*"
                value={this.state.reportGenerationForm.statusType}
                onChange={(evt) =>
                  this.updateFormData(evt, "reportGenerationForm", "statusType")
                }
              >
                {config.reports.statusTypes.map((statusType, i: number) => (
                  <option key={i} value={statusType}>
                    {statusType}
                  </option>
                ))}
              </select>
            </div>
            <div className="label-input-container">
              <label>Location*:</label>
              <input
                className="auth-view-form-input"
                type="text"
                placeholder="Location*"
                value={this.state.reportGenerationForm.location}
                onChange={(e) =>
                  this.updateFormData(e, "reportGenerationForm", "location")
                }
              />
            </div>
            <div className="label-input-container">
              <label>Total Days In Location*:</label>
              <input
                className="auth-view-form-input"
                type="number"
                placeholder={`Total Days [MAX: ${totalDaysThreshold}]*`}
                value={this.state.reportGenerationForm.totalDays}
                onChange={(e) =>
                  this.updateFormData(e, "reportGenerationForm", "totalDays")
                }
              />
            </div>
            <div className="label-input-container">
              <label>Total Options To Do*:</label>
              <input
                className="auth-view-form-input"
                type="number"
                placeholder={`Total Options [MAX: ${totalOptionsThreshold}]*`}
                value={this.state.reportGenerationForm.totalOptions}
                onChange={(e) =>
                  this.updateFormData(e, "reportGenerationForm", "totalOptions")
                }
              />
            </div>
            <div className="label-input-container">
              <label>Max Budget*:</label>
              <input
                className="auth-view-form-input"
                type="number"
                placeholder="Budget*"
                value={this.state.reportGenerationForm.budget}
                onChange={(e) =>
                  this.updateFormData(e, "reportGenerationForm", "budget")
                }
              />
            </div>
            <div className="label-input-container">
              <label>Currency*:</label>
              <select
                className="pp-form-select form-select"
                placeholder="Currency ($USD)*"
                value={this.state.reportGenerationForm.currency}
                onChange={(evt) =>
                  this.updateFormData(evt, "reportGenerationForm", "currency")
                }
              >
                {config.stripe.commonCurrencyCodes.map((cData, i: number) => (
                  <option
                    key={i}
                    value={cData.code}
                  >{`${cData.name} (${cData.code})`}</option>
                ))}
              </select>
            </div>
            {/* <div className="label-input-container">
              <label>Promo Code:</label>
              <input
                className="auth-view-form-input"
                type="text"
                placeholder="Promo Code"
                value={this.state.reportGenerationForm.promoCode}
                onChange={(e) =>
                  this.updateFormData(e, "reportGenerationForm", "promoCode")
                }
              />
            </div> */}
            {this.renderPlannerActionButtons()}
          </form>
        </div>
      );
    }
  }

  renderPlannerActionButtons() {
    if (!this.props.user.subscribed) {
      return (
        <div className="report-content-actions row">
          <button
            className="common-update-form-button planner-action-button col-md-6 col-12"
            onClick={this.handleJoinProPlan}
          >
            Subscribe
          </button>
          <button
            className="common-update-form-button planner-action-button col-md-6 col-12"
            onClick={this.generateReport}
          >
            Generate
          </button>
        </div>
      );
    }
    return (
      <div className="report-content-actions row">
        <button
          className="common-update-form-button planner-action-button-full-width col-12"
          onClick={this.generateReport}
        >
          Generate Plan
        </button>
      </div>
    );
  }

  reviewPlanData(planData: Object | any) {
    // TODO: Review and update planData if values are missing
    return planData;
  }

  async handleJoinProPlan(evt?: Event | any) {
    try {
      if (evt) {
        evt.preventDefault();
      }

      logIssueNetworkRequest("handleJoinProPlan");

      displayToastMessage("info", "Loading...");

      Mixpanel.track("Subscribe (start)", {});

      const jppRes: JoinProPlanResponse = await this.PaymentService.joinProPlan(
        {
          userID: this.props.user.id,
        }
      );
      if (jppRes.error) {
        Mixpanel.track("Subscribe (error)", {});
        throw new Error(jppRes.error);
      }

      Mixpanel.track("Subscribe (success)", {});

      displayToastMessage("success", "Success");

      (window as Window).location = jppRes.sessionURL;
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleJoinProPlan", error);
      displayToastMessage("error", postFormErrorLabel("Subscribe"));
    }
  }

  generateReport(evt: Event | any) {
    this.setState({ planData: null, loading: true }, async () => {
      try {
        evt.preventDefault();

        logIssueNetworkRequest("generateReport");

        let totalDaysThreshold = 7;
        let totalOptionsThreshold = 7;
        if (!this.props.user.subscribed) {
          totalDaysThreshold = 1;
          totalOptionsThreshold = 1;
        }

        if (this.state.reportGenerationForm.totalDays > totalDaysThreshold) {
          displayToastMessage(
            "error",
            `For now non-subscribers, total days can not be greater than ${totalDaysThreshold}`
          );
          return this.setState({ loading: false });
        }
        if (
          this.state.reportGenerationForm.totalOptions > totalOptionsThreshold
        ) {
          displayToastMessage(
            "error",
            `For non-subscribers, total options can not be greater than ${totalOptionsThreshold}`
          );
          return this.setState({ loading: false });
        }

        displayToastMessage("info", "Generating...please wait");

        Mixpanel.track("Generate Report (start)", {});

        const grRes: GenerateReportResponse =
          await this.ReportService.generateReport(
            this.state.reportGenerationForm
          );
        if (grRes.error) {
          Mixpanel.track("Generate Report (error)", {});
          throw new Error(grRes.error);
        }

        Mixpanel.track("Generate Report (success)", {});

        displayToastMessage("success", "Plan generated! Try another :D");

        this.setState({
          planData: this.reviewPlanData(grRes.finalReport),
          loading: false,
          currentView: "summary",
        });
      } catch (error: Error | any) {
        this.setState({ loading: false }, () => {
          logIssueNetworkRequestError("generateReport", error);
          displayToastMessage(
            "error",
            postFormErrorLabel("Generate Plan/Report")
          );
        });
      }
    });
  }

  async handleReviewPlan(
    evt: Event | any,
    planHistoryID: number | any,
    newReview: boolean | any
  ) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleReviewPlan");

      displayToastMessage("info", "Loading...");

      const rpRes: ReviewPlanResponse = await this.HistoryService.reviewPlan({
        planHistoryID,
        newReview,
        userID: this.props.user.id,
      });
      if (rpRes.error) {
        throw new Error(rpRes.error);
      }

      displayToastMessage("success", "Success");
    } catch (error: Error | any) {
      logIssueNetworkRequestError("generateReport", error);
      displayToastMessage("error", postFormErrorLabel("Generate Plan/Report"));
    }
  }

  renderPlanDetails() {
    if (this.state.currentView !== "form") {
      if (this.state.loading) {
        return <LoadingAnimation />;
      }
      return (
        <PlanDetails
          currentView={this.state.currentView}
          locationImage={this.state.locationImage}
          planData={this.state.planData}
          generateReport={this.generateReport}
          onHandleReviewPlan={this.handleReviewPlan}
          goBackToForm={(evt: Event | any) => {
            evt.preventDefault();
            this.setState({ currentView: "form", planData: null });
          }}
        />
      );
    }
  }

  render() {
    return (
      <div className="planner-container common-home-content-container">
        {this.renderHeader()}
        {this.renderGenerationForm()}
        {this.renderPlanDetails()}
      </div>
    );
  }
}

const mapStateToProps = (state: Object | any) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(Planner) as any;
