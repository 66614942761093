import React from "react";
import { connect } from "react-redux";
import { IUser, UpdateAccountResponse } from "../../../interfaces/user";
import { setUser } from "../../../redux/actions/user";
import AccountService from "../../../services/Account";
import { errorsToLabels } from "../../../utils/errors";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
  logUpdatedState,
} from "../../../utils/loggers";
import { displayToastMessage } from "../../../utils/toasts";
import "./index.css";

class Settings extends React.Component<any, any> {
  AccountService: AccountService;
  constructor(props: any) {
    super(props);

    console.log("props settings:", props);

    this.state = {
      currentView: "account",
      navBarItems: ["Account"],
      password: "",
      updatedAccount: {
        email: "",
      },
    };

    this.AccountService = new AccountService();

    this.renderCurrentSettingsView = this.renderCurrentSettingsView.bind(this);
    this.renderAccountSettings = this.renderAccountSettings.bind(this);
    this.updateAccountForm = this.updateAccountForm.bind(this);
    this.handleUpdateAccount = this.handleUpdateAccount.bind(this);
  }

  renderCurrentSettingsView() {
    switch (this.state.currentView) {
      case "Account":
        return this.renderAccountSettings();
      default:
        return this.renderAccountSettings();
    }
  }

  updateAccountForm(evt: Event | any, formKey: string) {
    this.setState(
      {
        updatedAccount: {
          ...this.state.updatedAccount,
          [formKey]: evt.target.value,
        },
      },
      () => logUpdatedState(this.state.updatedAccount, null, true)
    );
  }

  async handleUpdateAccount(evt: Event | any) {
    try {
      logIssueNetworkRequest("handleUpdateAccount");

      displayToastMessage("info", "Loading...");

      const uaRes: UpdateAccountResponse =
        await this.AccountService.updateAccount({
          userID: String(this.props.user.id),
          email: this.props.user.email,
          password: this.state.password,
          updatedAccount: this.state.updatedAccount,
        });
      if (uaRes.error) {
        throw new Error(uaRes.error);
      }

      displayToastMessage("success", "Updated account!");

      this.props.dispatchSetUser({
        ...this.props.user,
        ...this.state.updatedAccount,
      });

      this.setState({ updatedAccount: { email: "" } });
    } catch (error: Error | any) {
      logIssueNetworkRequestError("AccountSettings.handleUpdateAccount", error);
      displayToastMessage("error", errorsToLabels.failedToUpdateAccount);
    }
  }

  renderAccountSettings() {
    return (
      <div className="account-settings-container">
        <h1 className="common-home-section-header-label">
          Account Information
        </h1>
        <div className="current-info-container">
          <p>
            <strong>Email Address:</strong> {this.props.user.email}
          </p>
        </div>
        <h1 className="common-home-section-header-label">Update Information</h1>
        <input
          className="common-update-form-input positioned-form-input"
          type="text"
          placeholder="New Email Address"
          value={this.state.updatedAccount.email}
          onChange={(evt) => this.updateAccountForm(evt, "email")}
        />
        <input
          className="common-update-form-input positioned-form-input"
          type="password"
          placeholder="Current Password"
          value={this.state.password}
          onChange={(evt) => this.setState({ password: evt.target.value })}
        />
        <button
          id="positioned-form-action-button"
          className="common-update-form-button"
          type="button"
          onClick={this.handleUpdateAccount}
        >
          Update
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="common-home-content-container">
        {this.renderCurrentSettingsView()}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetUser: (user: IUser | any) => dispatch(setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
