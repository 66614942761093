import React from "react";
import { connect } from "react-redux";
import AdsManager from "../../../components/AdsManager";
import LoadingAnimation from "../../../components/LoadingAnimation";
import { ListHistoryResponse } from "../../../interfaces/history";
import HistoryService from "../../../services/History";
import { logIssueNetworkRequestError } from "../../../utils/loggers";
import { displayToastMessage } from "../../../utils/toasts";
import "./index.css";

class History extends React.Component<any, any> {
  HistoryService: HistoryService;
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      totalFound: 0,
      plans: null,
      paginationOptions: {
        orderByKey: "created_at",
        orderByValue: "DESC",
        offset: 0,
        limit: 6,
      },
      cardImage: process.env.PUBLIC_URL + "/report-cover-image.webp",
    };

    this.HistoryService = new HistoryService();

    this.loadPlansData = this.loadPlansData.bind(this);
    this.renderAds = this.renderAds.bind(this);
    this.handleLoadMorePlans = this.handleLoadMorePlans.bind(this);
    this.renderLoadMoreButtons = this.renderLoadMoreButtons.bind(this);
  }

  async componentDidMount() {
    await this.loadPlansData();
  }

  async loadPlansData() {
    try {
      const lhRes: ListHistoryResponse = await this.HistoryService.listHistory({
        userID: this.props.user.id,
        offset: this.state.paginationOptions.offset,
        limit: this.state.paginationOptions.limit,
        orderByKey: this.state.paginationOptions.orderByKey,
        orderByValue: this.state.paginationOptions.orderByValue,
      });
      if (lhRes.error) {
        throw new Error(lhRes.error);
      }
      if (lhRes.sessionURL) {
        displayToastMessage("error", "Subscription required. Please try again");
        // return this.setState({ loading: false }, () => {
        //   window.open(grRes.sessionURL, "_blank")?.focus();
        // });
        (window as Window).location = lhRes.sessionURL;
        return;
      }

      this.setState({
        loading: false,
        totalFound: lhRes.totalFound,
        plans: lhRes.plans,
      });
    } catch (error: Error | any) {
      logIssueNetworkRequestError("History.cdm()", error);
      displayToastMessage("error", "Failed to load history. Please try again");
    }
  }

  handleLoadMorePlans(offset: number) {
    this.setState(
      {
        paginationOptions: {
          ...this.state.paginationOptions,
          offset,
        },
      },
      async () => {
        displayToastMessage("info", "Loading");
        await this.loadPlansData();
      }
    );
  }

  renderHeader() {
    return (
      <header className="planner-header">
        <h1 className="common-home-content-header-label">History</h1>
        <p>*For subscribers only</p>
        {this.renderAds()}
      </header>
    );
  }

  renderAds() {
    if (!this.props.user.subscribed) {
      return <AdsManager />;
    }
  }

  renderHistoryList() {
    if (this.state.loading) {
      return <LoadingAnimation />;
    }
    return (
      <div className="row history-list-container">
        <h1 className="total-found">Total Found: {this.state.totalFound}</h1>
        {this.state.plans.map((planData: any, key: number) => (
          <div
            key={key}
            id="common-preview-card-container"
            className="card col-xxl-4 col-12 plan-card-container"
            onClick={(e) => {
              displayToastMessage("info", "Loading...");
              this.props.onPlanCardClicked(e, {
                ...planData.plan,
                planHistoryID: planData.id,
              });
            }}
          >
            <img
              src={this.state.cardImage}
              className="card-img-top"
              alt={`plan-preview-${key}`}
            />
            <div className="common-preview-card-body card-body">
              <h1 className="card-title card-label">
                Created:{" "}
                {new Date(Number(planData.created_at)).toLocaleDateString()}
              </h1>
              <p className="card-text">
                <strong>Location:</strong> {planData.plan.location}
              </p>
              <p className="card-text">
                <strong>Activity Type:</strong> {planData.plan.activityType}
              </p>
              <p className="card-text">
                <strong>Status:</strong> {planData.plan.statusType}
              </p>
              <p className="card-text">
                <strong>Recommendations:</strong>{" "}
                {planData.plan.recommendations.length}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderLoadMoreButtons() {
    if (this.state.totalFound !== 0) {
      const { limit, offset } = this.state.paginationOptions;
      const disablePrevButton = offset === 0;
      const disableNextButton = limit > this.state.plans.length;
      return (
        <div className="load-buttons-container">
          <button
            disabled={disablePrevButton}
            className={`${
              disablePrevButton ? "disabled-load-button" : "load-button"
            }`}
            onClick={(_) => this.handleLoadMorePlans(offset - limit)}
          >
            Prev
          </button>
          <button
            disabled={disableNextButton}
            className={`${
              disableNextButton ? "disabled-load-button" : "load-button"
            }`}
            onClick={(_) => this.handleLoadMorePlans(offset + limit)}
          >
            Next
          </button>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="history-container common-home-content-container">
        {this.renderHeader()}
        {this.renderHistoryList()}
        {this.renderLoadMoreButtons()}
      </div>
    );
  }
}

const mapStateToProps = (state: Object | any) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(History) as any;
