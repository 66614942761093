import React from "react";
import { connect } from "react-redux";
import LoadingAnimation from "../../components/LoadingAnimation";
import config from "../../config";
import { IUser } from "../../interfaces/user";
import { setUser } from "../../redux/actions/user";
import SessionService from "../../services/Session";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
} from "../../utils/loggers";
import { authRoutePath } from "../Router";
import History from "./History";
import Planner from "./Planner";
import Settings from "./Settings";
import "./index.css";
import { displayToastMessage } from "../../utils/toasts";
import { errorsToLabels } from "../../utils/errors";

class Home extends React.Component<any, any> {
  SessionService: SessionService;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      currentView: "Planner",
      navBarItems: ["Planner", "History", "Billing", "Settings", "Sign Out"],
      history: props.history,
      plannerCurrentView: undefined,
      currerntPlanData: undefined,
    };

    this.SessionService = new SessionService();

    this.navLinkIsActive = this.navLinkIsActive.bind(this);
    this.updateHomeView = this.updateHomeView.bind(this);
    this.handlePlanCardClicked = this.handlePlanCardClicked.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.renderSideNavBar = this.renderSideNavBar.bind(this);
    this.renderMobileSideNavBar = this.renderMobileSideNavBar.bind(this);
    this.renderCurrentHomeView = this.renderCurrentHomeView.bind(this);
  }

  async componentDidMount() {
    try {
      // Example: State transition from the Auth view
      if (
        this.props.location.state &&
        this.props.location.state.session &&
        this.props.user.id
      ) {
        return this.setState({ loading: false });
      }

      const rsRes = await this.SessionService.reviewSession();
      if (!rsRes.success) {
        await this.SessionService.signOut();
        return this.props.history.replace("/auth", { session: false });
      }
      // Update Redux State with User Data
      this.props.dispatchSetUser(rsRes.userSessionData);

      this.setState({ loading: false });
    } catch (error: Error | any) {
      logIssueNetworkRequestError("Home.componentDidMount", error);
      displayToastMessage(
        "error",
        errorsToLabels.failedToFetchRequiredUserData
      );
      try {
        await this.SessionService.signOut();
      } catch (error: Error | any) {
        logIssueNetworkRequestError(
          "Home.componentDidMount (this.SessionService.signOut())",
          error
        );
      } finally {
        this.props.history.replace(authRoutePath, {
          session: false,
        });
      }
    }
  }

  navLinkIsActive(currentView: string) {
    return currentView === this.state.currentView ? "nav-link-active" : "";
  }

  updateHomeView(
    e: Event | any,
    currentView: string,
    isMobile: boolean = false
  ) {
    if (currentView === "Sign Out") {
      return this.handleSignOut();
    }
    if (isMobile) {
      if (currentView === "Billing") {
        // return setTimeout(() => {
        //   window.open(config.links.stripeCustomerPortalURL, "_blank");
        // });
        (window as Window).location = config.links.stripeCustomerPortalURL;
        return;
      }
      if (currentView === "Support") {
        // return setTimeout(() => {
        //   window.open(
        //     `mailto:${config.emails.supportEmail}?subject=Customer%20Support%20Request`,
        //     "_blank"
        //   );
        // });
        (
          window as Window
        ).location = `mailto:${config.emails.supportEmail}?subject=Customer%20Support%20Request`;
        return;
      }
    }

    e.preventDefault();
    this.setState({
      currentView,
      plannerCurrentView: "form",
      currerntPlanData: undefined,
    });

    return false;
  }

  async handleSignOut() {
    try {
      logIssueNetworkRequest("handleSignOut");

      displayToastMessage("info", "Signing out...");

      await this.SessionService.signOut();

      this.props.history.replace(authRoutePath, { session: false });
    } catch (error: Error | any) {
      logIssueNetworkRequestError("Home.handleSignOut", error);
      displayToastMessage("error", errorsToLabels.failedToSignOut);
    }
  }

  renderSideNavBar() {
    return (
      <nav className="home-content-side-nav nav flex-column col-xxl-1">
        <div className="nav-link-container nav-item">
          <a
            className={`nav-link ${this.navLinkIsActive("reports")}`}
            href="/home#view=reports"
            onClick={(e) => this.updateHomeView(e, "Planner")}
          >
            Planner
          </a>
        </div>
        <div className="nav-link-container nav-item">
          <a
            className={`nav-link ${this.navLinkIsActive("reports")}`}
            href="/home#view=reports"
            onClick={(e) => this.updateHomeView(e, "History")}
          >
            History
          </a>
        </div>
        <div className="nav-link-container nav-item">
          <a
            className={`nav-link ${this.navLinkIsActive("Billing")}`}
            href={config.links.stripeCustomerPortalURL}
            target="_blank"
            rel="noreferrer"
          >
            Billing
          </a>
        </div>
        <div className="nav-link-container nav-item">
          <a
            className="nav-link"
            href={`mailto:${config.emails.supportEmail}?subject=Customer%20Support%20Request`}
          >
            Support
          </a>
        </div>
        <div className="nav-link-container nav-item">
          <a
            className={`nav-link ${this.navLinkIsActive("settings")}`}
            href="/home#view=settings"
            onClick={(e) => this.updateHomeView(e, "Settings")}
          >
            Settings
          </a>
        </div>
        <div className="nav-link-container nav-item">
          <a
            className="nav-link"
            href="/home#view=signout"
            onClick={(e) => this.handleSignOut()}
          >
            Sign Out
          </a>
        </div>
      </nav>
    );
  }

  renderMobileSideNavBar() {
    return (
      <select
        className="home-content-mobile-side-nav form-select"
        placeholder="Planner"
        value={this.state.curerntView}
        onChange={(evt) => this.updateHomeView(evt, evt.target.value, true)}
      >
        <option value="Planner">Planner</option>
        <option value="History">History</option>
        <option value="Billing">Billing</option>
        <option value="Support">Support</option>
        <option value="Settings">Settings</option>
        <option value="Sign Out">Sign Out</option>
      </select>
    );
  }

  handlePlanCardClicked(e: Event | any, planData: Object | any) {
    e.preventDefault();
    this.setState({
      currentView: "Planner",
      plannerCurrentView: "historicalSummary",
      currerntPlanData: planData,
    });
  }

  renderCurrentHomeView() {
    switch (this.state.currentView) {
      case "History":
        return (
          <History
            history={this.state.history}
            onPlanCardClicked={this.handlePlanCardClicked}
          />
        );
      case "Planner":
        return (
          <Planner
            history={this.state.history}
            currentView={this.state.plannerCurrentView}
            planData={this.state.currerntPlanData}
          />
        );
      case "Settings":
        return <Settings history={this.state.history} />;
      default:
        return (
          <Planner
            history={this.state.history}
            currentView={this.state.plannerCurrentView}
            planData={this.state.currerntPlanData}
          />
        );
    }
  }

  render() {
    if (this.state.loading) {
      return <LoadingAnimation />;
    }
    return (
      <div className="home-container">
        <div className="home-header-container">
          <div className="home-brand-label-container">
            <h1>{config.labels.brandName}</h1>
          </div>
        </div>
        <div className="home-content-container row">
          {this.renderSideNavBar()}
          {this.renderMobileSideNavBar()}
          <div className="home-content-type-container col-xxl-11 col-12">
            {this.renderCurrentHomeView()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Object | any) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch: Function | any) => ({
  dispatchSetUser: (user: IUser | any) => dispatch(setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home as any);
