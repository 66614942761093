import CommonService from "../Common";
import {
  ListHistoryRequest,
  ReviewPlanRequest,
} from "../../interfaces/history";

interface History {
  pathPrefix?: string;
  CommonService: CommonService | any;
}

class HistoryService implements History {
  pathPrefix?: string | undefined;
  CommonService: CommonService | any;

  constructor() {
    this.pathPrefix = "/api/v1/history";

    this.CommonService = new CommonService();
  }

  listHistory(reqBody: ListHistoryRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/list`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  reviewPlan(reqBody: ReviewPlanRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/review`, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }
}

export default HistoryService;
