import React from "react";
// import config from "../../config";
import "./index.css";

class AdsManager extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {
    const script = document.createElement("script");

    script.src = "https://ophoacit.com/1?z=5901065";
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    // return (
    //   <ins
    //     className="adsbygoogle"
    //     style={{ display: "block" }}
    //     data-ad-test={config.ads.adClientID}
    //     data-ad-client={config.ads.adClientID}
    //     data-ad-slot={config.ads.headerBannerAdSlotNumber}
    //     data-ad-format="auto"
    //     data-full-width-responsive="true"
    //   ></ins>
    // );
    return (
      <iframe
        title="aa"
        data-aa="2206150"
        src="//acceptable.a-ads.com/2206150"
        style={{
          border: "1px solid black",
          padding: "0",
          width: "100%",
          height: "auto",
          overflow: "hidden",
          backgroundColor: "transparent",
          marginBottom: "25px",
        }}
      ></iframe>
    );
  }
}
export default AdsManager;
