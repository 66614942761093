import CommonService from "../Common";
import {
  GenerateReportRequest,
  SyncToICALRequest,
} from "../../interfaces/report";

interface Report {
  pathPrefix?: string;
  CommonService: CommonService | any;
}

class ReportService implements Report {
  pathPrefix?: string | undefined;
  CommonService: CommonService | any;

  constructor() {
    this.pathPrefix = "/api/v1/report";

    this.CommonService = new CommonService();
  }

  generateReport(reqBody: GenerateReportRequest) {
    reqBody.startingDateTimeStamp = new Date().getTime();
    if (reqBody.promoCode === "") {
      reqBody.promoCode = undefined;
    }
    return fetch(
      "https://p3yy75cz4rhxs3aunzqbnj3ww40kgcrp.lambda-url.us-east-1.on.aws/api/v1/report/generate",
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(reqBody),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status < 200 || response.status >= 400) {
          throw new Error("Internal server error. Please contact support.");
        }
        return response.json();
      })
      .catch((error) => {
        return { error };
      });
  }

  syncToICal(reqBody: SyncToICALRequest) {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    return fetch(
      `${this.CommonService.apiDomain}${this.pathPrefix}/sync/ical`,
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(reqBody),
        headers,
      }
    )
      .then((response) => {
        if (response.status < 200 || response.status >= 400) {
          throw new Error("Internal server error. Please contact support.");
        }
        return response.arrayBuffer();
      })
      .catch((error) => {
        return { error };
      });
  }
}

export default ReportService;
