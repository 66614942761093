// Types: (https://github.com/fkhadra/react-toastify#toast)
import { toast, ToastContent, ToastOptions, TypeOptions } from "react-toastify";

export const displayToastMessage = (
  type: TypeOptions,
  message: ToastContent,
  options: ToastOptions = {
    autoClose: 4000,
    position: toast.POSITION.BOTTOM_LEFT,
  }
) => {
  if (process.env.NODE_ENV !== "production") {
    console.log("utils.toast.displayToastMessage()", type, message);
  }

  switch (type) {
    case "success":
      return toast.success(message, options);
    case "error":
      return toast.error(message, options);
    case "info":
      return toast.info(message, options);
    case "warning":
      return toast.warning(message, options);
  }
};
