import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import config from "../../config";
import {
  ActivateAccountRequest,
  AuthState,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  LogInResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SignUpLogInRequest,
  SignUpResponse,
} from "../../interfaces/auth";
import { IUser } from "../../interfaces/user";
import { setUser } from "../../redux/actions/user";
import AccountService from "../../services/Account";
import PaymentService from "../../services/Report";
import SessionService from "../../services/Session";
import { errorsToLabels, postFormErrorLabel } from "../../utils/errors";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
  logUpdatedState,
} from "../../utils/loggers";
import { displayToastMessage } from "../../utils/toasts";
import "./index.css";
import { homeRoutePath } from "../Router";
import { Mixpanel } from "../../utils/analytics";

class Auth extends React.Component<any, AuthState | any> {
  AccountService: AccountService;
  PaymentService: PaymentService;
  SessionService: SessionService;

  constructor(props: any) {
    super(props);

    this.state = {
      currentView: "Log In",
      signUpLogInForm: {
        email: "",
        password: "",
      } as SignUpLogInRequest,
      activateAccountForm: {
        email: "",
        activationToken: "",
      } as ActivateAccountRequest,
      forgotPasswordForm: {
        email: "",
      } as ForgotPasswordRequest,
      resetPasswordForm: {
        email: "",
        newPassword: "",
        confirmPassword: "",
        resetToken: "",
      } as ResetPasswordRequest,
    };

    this.AccountService = new AccountService();
    this.PaymentService = new PaymentService();
    this.SessionService = new SessionService();

    this.renderSignUpForm = this.renderSignUpForm.bind(this);
    this.renderActivateAccountForm = this.renderActivateAccountForm.bind(this);
    this.renderForgotPasswordForm = this.renderForgotPasswordForm.bind(this);
    this.renderResetPasswordForm = this.renderResetPasswordForm.bind(this);
    this.renderLogInForm = this.renderLogInForm.bind(this);

    this.updateFormData = this.updateFormData.bind(this);

    this.handleSignUp = this.handleSignUp.bind(this);
    this.handleActivateAccount = this.handleActivateAccount.bind(this);
    this.handleLogIn = this.handleLogIn.bind(this);
    this.handleLogInSuccess = this.handleLogInSuccess.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  async componentDidMount() {
    try {
      const parsedQuery = queryString.parse(this.props.location.search);
      if (parsedQuery.stripeReturn) {
        let toastType: any =
          parsedQuery.stripeReturn === "1" ? "success" : "error";
        let message =
          parsedQuery.stripeReturn === "1"
            ? "Success"
            : "Stripe connect creation failed. Please try again and/or contact support";
        displayToastMessage(toastType, message);
      }
      if (parsedQuery.email && parsedQuery.view === "activate") {
        const activationToken = parsedQuery.token ? parsedQuery.token : "";

        displayToastMessage(
          "success",
          "Success :D Check your email to activate your account AND MAKE SURE TO RESET YOUR TEMPORARY PASSWORD."
        );

        return this.setState({
          currentView: "Activate Account",
          activateAccountForm: {
            activationToken,
            email: parsedQuery.email,
          },
        });
      }

      if (
        this.props.location.state &&
        this.props.location.state.session === false
      ) {
        return;
      }
      const sessionData = await this.SessionService.reviewSession();
      if (sessionData.success) {
        this.props.dispatchSetUser({
          id: sessionData.userID,
        });
        this.props.history.replace(homeRoutePath, {
          userID: sessionData.userID,
        });
      }
    } catch (error: Error | any) {
      logIssueNetworkRequestError("Auth.componentDidMount", error);
    }
  }

  renderAuthView() {
    return (
      <div className="auth-view-container row">
        <div className="auth-view-content-container col-md-7 col-12">
          {this.renderAuthViewHeader()}
          {this.renderAuthViewContent()}
        </div>
        <div className="auth-view-background col-md-5"></div>
      </div>
    );
  }

  renderAuthViewHeader() {
    return (
      <div className="auth-view-header">
        <h1>{config.labels.brandName}</h1>
      </div>
    );
  }

  renderAuthViewContent() {
    return (
      <div className="auth-view-content">
        <div className="auth-view-content-header">
          <h2>{this.state.currentView}</h2>
        </div>
        {this.renderAuthFormContent()}
      </div>
    );
  }

  renderAuthFormContent() {
    switch (this.state.currentView) {
      case "Sign Up":
        return this.renderSignUpForm();
      case "Activate Account":
        return this.renderActivateAccountForm();
      case "Forgot Password":
        return this.renderForgotPasswordForm();
      case "Reset Password":
        return this.renderResetPasswordForm();
      case "Log In":
        return this.renderLogInForm();
    }
  }

  renderSignUpForm() {
    return (
      <div className="auth-view-content-form-container">
        <form className="auth-view-form sign-up-form">
          <input
            className="auth-view-form-input"
            type="text"
            placeholder="Email address"
            value={this.state.signUpLogInForm.email}
            onChange={(e) => this.updateFormData(e, "signUpLogInForm", "email")}
          />
          <input
            className="auth-view-form-input"
            type="password"
            placeholder="Password"
            value={this.state.signUpLogInForm.password}
            onChange={(e) =>
              this.updateFormData(e, "signUpLogInForm", "password")
            }
          />
          <input
            className="auth-view-form-input-button common-button"
            type="button"
            value="Sign Up"
            onClick={this.handleSignUp}
          />
          <div className="auth-view-form-options-container">
            <a
              className="auth-view-form-options-link"
              href="#logIn"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ currentView: "Log In" } as AuthState);
              }}
            >
              Log In
            </a>
          </div>
        </form>
      </div>
    );
  }

  renderActivateAccountForm() {
    return (
      <div className="auth-view-content-form-container">
        <form className="auth-view-form activate-account-form">
          <input
            className="auth-view-form-input"
            type="text"
            placeholder="Email address"
            value={this.state.activateAccountForm.email}
            onChange={(e) =>
              this.updateFormData(e, "activateAccountForm", "email")
            }
          />
          <input
            className="auth-view-form-input"
            type="text"
            placeholder="Activation token"
            value={this.state.activateAccountForm.activationToken}
            onChange={(e) =>
              this.updateFormData(e, "activateAccountForm", "activationToken")
            }
          />
          <input
            className="auth-view-form-input-button common-button"
            type="button"
            value="Activate"
            onClick={this.handleActivateAccount}
          />
        </form>
      </div>
    );
  }

  renderForgotPasswordForm() {
    return (
      <div className="auth-view-content-form-container">
        <form className="auth-view-form forgot-password-form">
          <input
            className="auth-view-form-input"
            type="text"
            placeholder="Email address"
            value={this.state.forgotPasswordForm.email}
            onChange={(e) =>
              this.updateFormData(e, "forgotPasswordForm", "email")
            }
          />
          <input
            className="auth-view-form-input-button common-button"
            type="button"
            value="Submit"
            onClick={this.handleForgotPassword}
          />
          <div className="auth-view-form-options-container">
            <a
              className="auth-view-form-options-link"
              href="#logIn"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ currentView: "Log In" } as AuthState);
              }}
            >
              Log In
            </a>
          </div>
        </form>
      </div>
    );
  }

  renderResetPasswordForm() {
    return (
      <div className="auth-view-content-form-container">
        <form className="auth-view-form reset-password-form">
          <input
            className="auth-view-form-input"
            type="text"
            placeholder="Email address"
            value={this.state.resetPasswordForm.email}
            onChange={(e) =>
              this.updateFormData(e, "resetPasswordForm", "email")
            }
          />
          <input
            className="auth-view-form-input"
            type="password"
            placeholder="New password"
            value={this.state.resetPasswordForm.newPassword}
            onChange={(e) =>
              this.updateFormData(e, "resetPasswordForm", "newPassword")
            }
          />
          <input
            className="auth-view-form-input"
            type="password"
            placeholder="Confirm password"
            value={this.state.resetPasswordForm.confirmPassword}
            onChange={(e) =>
              this.updateFormData(e, "resetPasswordForm", "confirmPassword")
            }
          />
          <input
            className="auth-view-form-input"
            type="text"
            placeholder="Reset token"
            value={this.state.resetPasswordForm.resetToken}
            onChange={(e) =>
              this.updateFormData(e, "resetPasswordForm", "resetToken")
            }
          />
          <input
            className="auth-view-form-input-button common-button"
            type="button"
            value="Submit"
            onClick={this.handleResetPassword}
          />
          <div className="auth-view-form-options-container">
            <a
              className="auth-view-form-options-link"
              href="#logIn"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ currentView: "Log In" } as AuthState);
              }}
            >
              Log In
            </a>
          </div>
        </form>
      </div>
    );
  }

  renderLogInForm() {
    return (
      <div className="auth-view-content-form-container">
        <form className="auth-view-form log-in-form">
          <input
            className="auth-view-form-input"
            type="text"
            placeholder="Email address"
            value={this.state.signUpLogInForm.email}
            onChange={(e) => this.updateFormData(e, "signUpLogInForm", "email")}
          />
          <input
            className="auth-view-form-input"
            type="password"
            placeholder="Password"
            value={this.state.signUpLogInForm.password}
            onChange={(e) =>
              this.updateFormData(e, "signUpLogInForm", "password")
            }
          />
          <input
            className="auth-view-form-input-button common-button"
            type="button"
            value="Log In"
            onClick={this.handleLogIn}
          />
          <div className="auth-view-form-options-container">
            <a
              className="auth-view-form-options-link"
              href="#forgotPassword"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ currentView: "Forgot Password" } as AuthState);
              }}
            >
              Forgot Password
            </a>
          </div>
          <div className="auth-view-form-options-container">
            <a
              className="auth-view-form-options-link"
              href="#signUp"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ currentView: "Sign Up" } as AuthState);
              }}
            >
              Sign Up
            </a>
          </div>
        </form>
      </div>
    );
  }

  updateFormData(evt: Event | any, formLabel: string, formKeyLabel: string) {
    this.setState(
      {
        [formLabel]: {
          ...this.state[formLabel],
          [formKeyLabel]: evt.target.value,
        },
      },
      () => logUpdatedState(this.state, formLabel)
    );
  }

  async handleSignUp(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleSignUp");

      displayToastMessage("info", "Loading...");

      const resBody: SignUpResponse = await this.AccountService.signUp(
        this.state.signUpLogInForm
      );
      if (resBody.error) {
        throw new Error(resBody.error);
      }

      Mixpanel.track("Sign Up", {
        email: this.state.signUpLogInForm.email,
        phoneNumber: this.state.signUpLogInForm.phoneNumber,
      });

      displayToastMessage(
        "success",
        "Please check your email for an activation token"
      );

      this.setState({ currentView: "Activate Account" } as AuthState);
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleSignUp", error);
      displayToastMessage("error", postFormErrorLabel("Sign Up"));
    }
  }

  async handleActivateAccount(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleActivateAccount");

      displayToastMessage("info", "Loading...");

      Mixpanel.track("Activate Account", {
        email: this.state.activateAccountForm.email,
      });

      const resBody: SignUpResponse = await this.AccountService.activateAccount(
        this.state.activateAccountForm
      );
      if (resBody.error) {
        throw new Error(resBody.error);
      }

      displayToastMessage("success", "Please log in again :)");

      this.setState({ currentView: "Log In" } as AuthState);
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleActivateAccount", error);
      displayToastMessage("error", postFormErrorLabel("Account Activation"));
    }
  }

  async handleLogIn(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleLogIn");

      displayToastMessage("info", "Loading...");

      const resBody: LogInResponse = await this.SessionService.logIn(
        this.state.signUpLogInForm
      );
      if (resBody.error) {
        if (resBody.error === errorsToLabels.userMustActivateAccount) {
          displayToastMessage(
            "success",
            "Please check your email for an activation token"
          );
          return this.setState({
            currentView: "Activate Account",
          } as AuthState);
        }
        throw new Error(resBody.error);
      }

      displayToastMessage("success", "Success");

      Mixpanel.identify(resBody.user.id);
      Mixpanel.instance.people.set_once({
        "First Login Date": new Date(),
        userID: resBody.user.id,
      });
      Mixpanel.track("Log In (start)", {
        email: this.state.signUpLogInForm.email,
        phoneNumber: this.state.signUpLogInForm.phoneNumber,
      });

      await this.handleLogInSuccess(resBody.user);
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleLogIn", error);
      displayToastMessage("error", postFormErrorLabel("Log In"));
    }
  }

  async handleLogInSuccess(user: IUser | any) {
    try {
      // Update Redux State with User Data
      this.props.dispatchSetUser(user);

      Mixpanel.track("Log In (Complete)", {
        userID: user.id,
        email: user.email,
      });

      // Segue to Streams (Home)
      this.props.history.replace(homeRoutePath, { session: true });
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleLogInSuccess", error);
      displayToastMessage("error", postFormErrorLabel("Post Log In"));
    }
  }

  async handleForgotPassword(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleForgotPassword");

      displayToastMessage("info", "Loading...");

      const resBody: ForgotPasswordResponse =
        await this.AccountService.forgotPassword(this.state.forgotPasswordForm);
      if (resBody.error) {
        throw new Error(resBody.error);
      }

      displayToastMessage(
        "success",
        "Please check your email for your reset token"
      );

      this.setState({ currentView: "Reset Password" } as AuthState);
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleForgotPassword", error);
      displayToastMessage("error", postFormErrorLabel("Forgot Password"));
    }
  }

  async handleResetPassword(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleResetPassword");

      displayToastMessage("info", "Loading...");

      const resBody: ResetPasswordResponse =
        await this.AccountService.resetPassword(this.state.resetPasswordForm);
      if (resBody.error) {
        throw new Error(resBody.error);
      }

      displayToastMessage("success", "Please log in again :)");

      this.setState({ currentView: "Log In" } as AuthState);
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleResetPassword", error);
      displayToastMessage("error", postFormErrorLabel("Reset Password"));
    }
  }

  render() {
    return this.renderAuthView();
  }
}

const mapStateToProps = (state: Object | any) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: Function | any) => ({
  dispatchSetUser: (user: IUser | any) => dispatch(setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth as any);
