import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import { Route, Switch } from "react-router-dom";
import { Cookies } from "react-cookie-consent";
import { ToastContainer } from "react-toastify";
import Auth from "../Auth";
import Home from "../Home";
// import LiveFeedSearch from "../LiveFeedSearch"; (Descoped for MVP)
import PageNotFound from "../PageNotFound";
import CookieConsentBanner, {
  removeAnalyticCookies,
} from "../../components/CookieConsentBanner";
import { history } from "../../redux";
import config from "../../config";
import StripeCheckoutStatus from "../StripeCheckoutStatus";

const handleProtectedRoutes = (Component: any, options: any) => {
  const consentCookie = Cookies.get(config.cookies.consentCookieLabel);
  if (consentCookie && consentCookie === "false") {
    removeAnalyticCookies();
  }
  return <Component {...options.history} />;
};

export const authRoutePath = "/auth";
export const homeRoutePath = "/home";
export const stripeCheckoutStatusRoutePath = "/paymentstatus";

const Router = (_: any) => {
  return (
    <ConnectedRouter history={history}>
      <div className="main">
        <Switch>
          <Route
            path="/"
            exact
            component={(h: History): any =>
              handleProtectedRoutes(Auth, {
                history: h,
              })
            }
          />
          <Route
            path={`${authRoutePath}*`}
            component={(h: History): any =>
              handleProtectedRoutes(Auth, {
                history: h,
              })
            }
          />
          <Route
            path={`${homeRoutePath}*`}
            component={(h: History): any =>
              handleProtectedRoutes(Home, {
                history: h,
              })
            }
          />
          <Route
            path={`${stripeCheckoutStatusRoutePath}*`}
            component={(h: History): any =>
              handleProtectedRoutes(StripeCheckoutStatus, {
                history: h,
              })
            }
          />
          <Route component={PageNotFound} />
        </Switch>
        <ToastContainer />
        <CookieConsentBanner />
      </div>
    </ConnectedRouter>
  );
};

const mapStateToProps = (state: any) => ({
  router: state.router,
});

export default connect(mapStateToProps, null)(Router);
