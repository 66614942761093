import CommonService from "../Common";
import { JoinProPlanRequest } from "../../interfaces/payment";

interface Payment {
  pathPrefix?: string;
  CommonService: CommonService | any;
}

class PaymentService implements Payment {
  pathPrefix?: string | undefined;
  CommonService: CommonService | any;

  constructor() {
    this.pathPrefix = "/api/v1/payment";

    this.CommonService = new CommonService();
  }

  joinProPlan(reqBody: JoinProPlanRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/subscribe`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }
}

export default PaymentService;
