import React from "react";
import "./index.css";

class PlanDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  renderPlanSummary(planData: Object | any) {
    return planData.recommendations.map(
      (recommendation: Object | any, key: number) => {
        return (
          <div key={key} className="recommendation-content-container">
            <div className="recommendation-content-section">
              <p className="day">Day #{recommendation.dayNumber}</p>
              {recommendation.activities.map(
                (activity: Object | any, k2: number) => (
                  <div key={k2} className="recommendation-activity-section">
                    <h2>Activity #{k2 + 1}</h2>
                    <p className="name">
                      Name: {activity.name}
                      {" ("}
                      <a
                        href={activity.location}
                        target="_blank"
                        rel="noreferrer"
                        className="location"
                      >
                        Location
                      </a>
                      {")"}
                    </p>

                    <p className="recommended-duration">
                      Website:{" "}
                      <a
                        href={activity.website}
                        target="_blank"
                        rel="noreferrer"
                        className="location"
                      >
                        {activity.website}
                      </a>
                    </p>
                    <p className="recommended-duration">
                      Description: {activity.description}
                    </p>
                    <p className="recommended-duration">
                      Recommended Attire: {activity.recommendedAttire}
                    </p>
                    <p className="recommended-duration">
                      Duration: ~{activity.recommendedDuration}
                    </p>
                    <p className="recommended-duration">
                      Cost: ~{activity.estimatedCost}
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        );
      }
    );
  }

  renderActionButtons() {
    if (this.props.currentView === "historicalSummary") {
      return (
        <div className="report-content-actions row">
          <button
            className="common-update-form-button planner-action-button-full-width col-12"
            onClick={this.props.goBackToForm}
          >
            Back
          </button>
        </div>
      );
    }
    return (
      <div className="report-content-actions row">
        <button
          className="common-update-form-button planner-action-button col-md-6 col-12"
          onClick={this.props.goBackToForm}
        >
          Back
        </button>
        <button
          className="common-update-form-button planner-action-button col-md-6 col-12"
          onClick={this.props.generateReport}
        >
          Shuffle
        </button>
      </div>
    );
  }

  renderReviewButtons() {
    if (this.props.currentView === "summary") {
      return (
        <div className="review-buttons-container">
          <i
            className="fa-solid fa-thumbs-up fa-2xl like-button"
            onClick={(e) =>
              this.props.onHandleReviewPlan(
                e,
                this.props.planData.planHistoryID,
                true
              )
            }
          ></i>
          <i
            className="fa-solid fa-thumbs-down fa-2xl dislike-button"
            onClick={(e) =>
              this.props.onHandleReviewPlan(
                e,
                this.props.planData.planHistoryID,
                false
              )
            }
          ></i>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="report-content-container">
        <h1 className="plan-heading-label">Itinerary</h1>
        <div className="plan-content">
          {this.renderPlanSummary(this.props.planData)}
          <div className="total-estimated-cost-container">
            <p>Total Estimated Cost: {this.props.planData.totalExpense}</p>
          </div>
          {this.renderReviewButtons()}
        </div>
        {this.renderActionButtons()}
      </div>
    );
  }
}

export default PlanDetails;
