const inProd = process.env.NODE_ENV === "production";
const config = {
  inProd,
  ads: {
    testMode: inProd ? "off" : "on",
    adClientID: inProd
      ? process.env.REACT_APP_AD_CLIENT_ID
      : "ca-pub-0000000000000000",
    headerBannerAdSlotNumber: inProd
      ? process.env.REACT_APP_HEADER_BANNER_AD_SLOT_NUMBER
      : "0000000000",
  },
  cookies: {
    consentCookieLabel:
      process.env.REACT_APP_CONSENT_COOKIE_LABEL || "consent-cookie",
    sessionCookieLabel: process.env.REACT_APP_SESSION_COOKIE_LABEL || "sl_st",
  },
  emails: {
    supportEmail:
      process.env.REACT_APP_SUPPORT_EMAIL || "gptmydaysupport@protonmail.com",
  },
  labels: {
    brandName: "GPTMyDay",
    domain: "gptmyday.com",
  },
  links: {
    baseAPIURL: inProd
      ? process.env.REACT_APP_PROD_API_DOMAIN
      : process.env.REACT_APP_DEV_API_DOMAIN,
    baseClientURL: inProd
      ? process.env.REACT_APP_WEB_APP_URL
      : "http://localhost:3000",
    stripeCustomerPortalURL: inProd
      ? "https://billing.stripe.com/p/login/14k00he561TqeaIfYY"
      : "https://billing.stripe.com/p/login/test_aEU7sK9ST2Ki0Xm9AA",
  },
  reports: {
    // TODO: Add more
    activityTypes: [
      "Dining",
      "Shopping",
      "Drinking",
      "Clubbing",
      "Sightseeing",
      "Hiking",
      "Parks/Monuments",
      "Mueseums",
      "Entertainment",
      "Aquatics",
    ],
    statusTypes: ["Solo", "Couples", "Group", "Family"],
  },
  stripe: {
    commonCurrencyCodes: [
      { name: "US Dollar", code: "USD" },
      { name: "Canadian Dollar", code: "CAD" },
      { name: "Euro", code: "EUR" },
      { name: "United Arab Emirates Dirham", code: "AED" },
      { name: "Afghan Afghani", code: "AFN" },
      { name: "Albanian Lek", code: "ALL" },
      { name: "Armenian Dram", code: "AMD" },
      { name: "Argentine Peso", code: "ARS" },
      { name: "Australian Dollar", code: "AUD" },
      { name: "Azerbaijani Manat", code: "AZN" },
      { name: "Bosnia-Herzegovina Convertible Mark", code: "BAM" },
      { name: "Bangladeshi Taka", code: "BDT" },
      { name: "Bulgarian Lev", code: "BGN" },
      { name: "Bahraini Dinar", code: "BHD" },
      { name: "Burundian Franc", code: "BIF" },
      { name: "Brunei Dollar", code: "BND" },
      { name: "Bolivian Boliviano", code: "BOB" },
      { name: "Brazilian Real", code: "BRL" },
      { name: "Botswanan Pula", code: "BWP" },
      { name: "Belarusian Ruble", code: "BYN" },
      { name: "Belize Dollar", code: "BZD" },
      { name: "Congolese Franc", code: "CDF" },
      { name: "Swiss Franc", code: "CHF" },
      { name: "Chilean Peso", code: "CLP" },
      { name: "Chinese Yuan", code: "CNY" },
      { name: "Colombian Peso", code: "COP" },
      { name: "Costa Rican Colón", code: "CRC" },
      { name: "Cape Verdean Escudo", code: "CVE" },
      { name: "Czech Republic Koruna", code: "CZK" },
      { name: "Djiboutian Franc", code: "DJF" },
      { name: "Danish Krone", code: "DKK" },
      { name: "Dominican Peso", code: "DOP" },
      { name: "Algerian Dinar", code: "DZD" },
      { name: "Estonian Kroon", code: "EEK" },
      { name: "Egyptian Pound", code: "EGP" },
      { name: "Eritrean Nakfa", code: "ERN" },
      { name: "Ethiopian Birr", code: "ETB" },
      { name: "British Pound Sterling", code: "GBP" },
      { name: "Georgian Lari", code: "GEL" },
      { name: "Ghanaian Cedi", code: "GHS" },
      { name: "Guinean Franc", code: "GNF" },
      { name: "Guatemalan Quetzal", code: "GTQ" },
      { name: "Hong Kong Dollar", code: "HKD" },
      { name: "Honduran Lempira", code: "HNL" },
      { name: "Croatian Kuna", code: "HRK" },
      { name: "Hungarian Forint", code: "HUF" },
      { name: "Indonesian Rupiah", code: "IDR" },
      { name: "Israeli New Sheqel", code: "ILS" },
      { name: "Indian Rupee", code: "INR" },
      { name: "Iraqi Dinar", code: "IQD" },
      { name: "Iranian Rial", code: "IRR" },
      { name: "Icelandic Króna", code: "ISK" },
      { name: "Jamaican Dollar", code: "JMD" },
      { name: "Jordanian Dinar", code: "JOD" },
      { name: "Japanese Yen", code: "JPY" },
      { name: "Kenyan Shilling", code: "KES" },
      { name: "Cambodian Riel", code: "KHR" },
      { name: "Comorian Franc", code: "KMF" },
      { name: "South Korean Won", code: "KRW" },
      { name: "Kuwaiti Dinar", code: "KWD" },
      { name: "Kazakhstani Tenge", code: "KZT" },
      { name: "Lebanese Pound", code: "LBP" },
      { name: "Sri Lankan Rupee", code: "LKR" },
      { name: "Lithuanian Litas", code: "LTL" },
      { name: "Latvian Lats", code: "LVL" },
      { name: "Libyan Dinar", code: "LYD" },
      { name: "Moroccan Dirham", code: "MAD" },
      { name: "Moldovan Leu", code: "MDL" },
      { name: "Malagasy Ariary", code: "MGA" },
      { name: "Macedonian Denar", code: "MKD" },
      { name: "Myanma Kyat", code: "MMK" },
      { name: "Macanese Pataca", code: "MOP" },
      { name: "Mauritian Rupee", code: "MUR" },
      { name: "Mexican Peso", code: "MXN" },
      { name: "Malaysian Ringgit", code: "MYR" },
      { name: "Mozambican Metical", code: "MZN" },
      { name: "Namibian Dollar", code: "NAD" },
      { name: "Nigerian Naira", code: "NGN" },
      { name: "Nicaraguan Córdoba", code: "NIO" },
      { name: "Norwegian Krone", code: "NOK" },
      { name: "Nepalese Rupee", code: "NPR" },
      { name: "New Zealand Dollar", code: "NZD" },
      { name: "Omani Rial", code: "OMR" },
      { name: "Panamanian Balboa", code: "PAB" },
      { name: "Peruvian Nuevo Sol", code: "PEN" },
      { name: "Philippine Peso", code: "PHP" },
      { name: "Pakistani Rupee", code: "PKR" },
      { name: "Polish Zloty", code: "PLN" },
      { name: "Paraguayan Guarani", code: "PYG" },
      { name: "Qatari Rial", code: "QAR" },
      { name: "Romanian Leu", code: "RON" },
      { name: "Serbian Dinar", code: "RSD" },
      { name: "Russian Ruble", code: "RUB" },
      { name: "Rwandan Franc", code: "RWF" },
      { name: "Saudi Riyal", code: "SAR" },
      { name: "Sudanese Pound", code: "SDG" },
      { name: "Swedish Krona", code: "SEK" },
      { name: "Singapore Dollar", code: "SGD" },
      { name: "Somali Shilling", code: "SOS" },
      { name: "Syrian Pound", code: "SYP" },
      { name: "Thai Baht", code: "THB" },
      { name: "Tunisian Dinar", code: "TND" },
      { name: "Tongan Paʻanga", code: "TOP" },
      { name: "Turkish Lira", code: "TRY" },
      { name: "Trinidad and Tobago Dollar", code: "TTD" },
      { name: "New Taiwan Dollar", code: "TWD" },
      { name: "Tanzanian Shilling", code: "TZS" },
      { name: "Ukrainian Hryvnia", code: "UAH" },
      { name: "Ugandan Shilling", code: "UGX" },
      { name: "Uruguayan Peso", code: "UYU" },
      { name: "Uzbekistan Som", code: "UZS" },
      { name: "Venezuelan Bolívar", code: "VEF" },
      { name: "Vietnamese Dong", code: "VND" },
      { name: "CFA Franc BEAC", code: "XAF" },
      { name: "CFA Franc BCEAO", code: "XOF" },
      { name: "Yemeni Rial", code: "YER" },
      { name: "South African Rand", code: "ZAR" },
      { name: "Zambian Kwacha", code: "ZMK" },
      { name: "Zimbabwean Dollar", code: "ZWL" },
    ],
  },
  webrtc: {
    iceServers: [
      {
        urls: "stun:relay.metered.ca:80",
      },
      {
        urls: "turn:relay.metered.ca:80",
        username: "17ee23e34331a83fa7738a95",
        credential: "aBdgw8rocIVxiKib",
      },
      {
        urls: "turn:relay.metered.ca:443",
        username: "17ee23e34331a83fa7738a95",
        credential: "aBdgw8rocIVxiKib",
      },
      {
        urls: "turn:relay.metered.ca:443?transport=tcp",
        username: "17ee23e34331a83fa7738a95",
        credential: "aBdgw8rocIVxiKib",
      },
      {
        urls: "stun:stun.healthtap.com:3478",
      },
      {
        urls: "stun:stun.wtfismyip.com:3478",
      },
      {
        urls: "stun:stun.syncthing.net:3478",
      },
      {
        urls: "stun:stun.bcs2005.net:3478",
      },
      {
        urls: "stun:stun.kotter.net:3478",
      },
      {
        urls: "stun:stun.avoxi.com:3478",
      },
      {
        urls: "stun:stun.kedr.io:3478",
      },
      {
        urls: "stun:stun.voip.aebc.com:3478",
      },
      {
        urls: "stun:stun.lovense.com:3478",
      },
      {
        urls: "stun:stun.siptrunk.com:3478",
      },
      {
        urls: "stun:stun.sonetel.com:3478",
      },
      {
        urls: "stun:stun3.l.google.com:19302",
      },
      {
        urls: "stun:stun.romancecompass.com:3478",
      },
      {
        urls: "stun:stun.f.haeder.net:3478",
      },
      {
        urls: "stun:stun.poetamatusel.org:3478",
      },
      {
        urls: "stun:stun.ladridiricette.it:3478",
      },
      {
        urls: "stun:stun.annatel.net:3478",
      },
      {
        urls: "stun:stun.arkh-edu.ru:3478",
      },
      {
        urls: "stun:stun.landvast.nl:3478",
      },
      {
        urls: "stun:stun.senstar.com:3478",
      },
      {
        urls: "stun:stun.sma.de:3478",
      },
      {
        urls: "stun:stun.londonweb.net:3478",
      },
      {
        urls: "stun:stun.uiltucssicilia.it:3478",
      },
      {
        urls: "stun:stun.solnet.ch:3478",
      },
      {
        urls: "stun:stun.futurasp.es:3478",
      },
      {
        urls: "stun:stun.crimeastar.net:3478",
      },
      {
        urls: "stun:stun.voipia.net:3478",
      },
      {
        urls: "stun:stun.callwithus.com:3478",
      },
      {
        urls: "stun:stun.xten.com:3478",
      },
      {
        urls: "stun:stun.cablenet-as.net:3478",
      },
      {
        urls: "stun:stun.ortopediacoam.it:3478",
      },
      {
        urls: "stun:stun.voipbuster.com:3478",
      },
      {
        urls: "stun:stun.linphone.org:3478",
      },
      {
        urls: "stun:stun.sipy.cz:3478",
      },
      {
        urls: "stun:stun3.l.google.com:19305",
      },
      {
        urls: "stun:stun.srce.hr:3478",
      },
      {
        urls: "stun:stun.hide.me:3478",
      },
      {
        urls: "stun:stun.skydrone.aero:3478",
      },
      {
        urls: "stun:stun.1und1.de:3478",
      },
      {
        urls: "stun:stun.atagverwarming.nl:3478",
      },
      {
        urls: "stun:stun.splicecom.com:3478",
      },
      {
        urls: "stun:stun.edwin-wiegele.at:3478",
      },
      {
        urls: "stun:stun.sipglobalphone.com:3478",
      },
      {
        urls: "stun:stun.cheapvoip.com:3478",
      },
      {
        urls: "stun:stun.sippeer.dk:3478",
      },
      {
        urls: "stun:stun.cibercloud.com.br:3478",
      },
      {
        urls: "stun:stun.cope.es:3478",
      },
      {
        urls: "stun:stun.sparvoip.de:3478",
      },
      {
        urls: "stun:stun.jumblo.com:3478",
      },
      {
        urls: "stun:stun.kanojo.de:3478",
      },
      {
        urls: "stun:stun.peethultra.be:3478",
      },
      {
        urls: "stun:stun.foad.me.uk:3478",
      },
      {
        urls: "stun:stun1.l.google.com:19305",
      },
      {
        urls: "stun:stun.antisip.com:3478",
      },
      {
        urls: "stun:stun.lleida.net:3478",
      },
      {
        urls: "stun:stun.mobile-italia.com:3478",
      },
      {
        urls: "stun:stun.totalcom.info:3478",
      },
      {
        urls: "stun:stun.easter-eggs.com:3478",
      },
      {
        urls: "stun:stun.sylaps.com:3478",
      },
      {
        urls: "stun:stun.odr.de:3478",
      },
      {
        urls: "stun:stun.coffee-sen.com:3478",
      },
      {
        urls: "stun:stun.smartvoip.com:3478",
      },
      {
        urls: "stun:stun.demos.su:3478",
      },
      {
        urls: "stun:stun.teliax.com:3478",
      },
      {
        urls: "stun:stun.schulinformatik.at:3478",
      },
      {
        urls: "stun:stun.voip.eutelia.it:3478",
      },
      {
        urls: "stun:stun.ippi.fr:3478",
      },
      {
        urls: "stun:stun.geonet.ro:3478",
      },
      {
        urls: "stun:stun.l.google.com:19302",
      },
      {
        urls: "stun:stun.easycall.pl:3478",
      },
      {
        urls: "stun:stun.intervoip.com:3478",
      },
      {
        urls: "stun:stun.actionvoip.com:3478",
      },
      {
        urls: "stun:stun.nexphone.ch:3478",
      },
      {
        urls: "stun:stun.dcalling.de:3478",
      },
      {
        urls: "stun:stun.labs.net:3478",
      },
      {
        urls: "stun:stun.mixvoip.com:3478",
      },
      {
        urls: "stun:stun.rolmail.net:3478",
      },
      {
        urls: "stun:stun.solomo.de:3478",
      },
      {
        urls: "stun:stun.jabbim.cz:3478",
      },
      {
        urls: "stun:stun.selasky.org:3478",
      },
      {
        urls: "stun:stun.url.net.au:3478",
      },
      {
        urls: "stun:stun.acquageraci.it:3478",
      },
      {
        urls: "stun:stun.netgsm.com.tr:3478",
      },
      {
        urls: "stun:stun.imp.ch:3478",
      },
      {
        urls: "stun:stun.epygi.com:3478",
      },
      {
        urls: "stun:stun.sonetel.net:3478",
      },
      {
        urls: "stun:stun.oncloud7.ch:3478",
      },
      {
        urls: "stun:stun.smsdiscount.com:3478",
      },
      {
        urls: "stun:stun.sipgate.net:3478",
      },
      {
        urls: "stun:stun.vomessen.de:3478",
      },
      {
        urls: "stun:stun.studio-link.de:3478",
      },
      {
        urls: "stun:stun.uabrides.com:3478",
      },
      {
        urls: "stun:stun.12voip.com:3478",
      },
      {
        urls: "stun:stun.myhowto.org:3478",
      },
      {
        urls: "stun:stun.ctafauni.it:3478",
      },
      {
        urls: "stun:stun.alphacron.de:3478",
      },
      {
        urls: "stun:stun.bearstech.com:3478",
      },
      {
        urls: "stun:stun.vincross.com:3478",
      },
      {
        urls: "stun:stun.neomedia.it:3478",
      },
      {
        urls: "stun:stun.sipthor.net:3478",
      },
      {
        urls: "stun:stun.files.fm:3478",
      },
      {
        urls: "stun:stun.sewan.fr:3478",
      },
      {
        urls: "stun:stun.meetwife.com:3478",
      },
      {
        urls: "stun:stun.counterpath.com:3478",
      },
      {
        urls: "stun:stun.moonlight-stream.org:3478",
      },
      {
        urls: "stun:stun.telnyx.com:3478",
      },
      {
        urls: "stun:stun.istitutogramscisiciliano.it:3478",
      },
      {
        urls: "stun:stun.medvc.eu:3478",
      },
      {
        urls: "stun:stun.ukh.de:3478",
      },
      {
        urls: "stun:stun.voipvoice.it:3478",
      },
      {
        urls: "stun:stun.vozelia.com:3478",
      },
      {
        urls: "stun:stun.trivenet.it:3478",
      },
      {
        urls: "stun:stun.ncic.com:3478",
      },
      {
        urls: "stun:stun.zentauron.de:3478",
      },
      {
        urls: "stun:stun.elitetele.com:3478",
      },
      {
        urls: "stun:stun.junet.se:3478",
      },
      {
        urls: "stun:stun.stadtwerke-eutin.de:3478",
      },
      {
        urls: "stun:stun.ringostat.com:3478",
      },
      {
        urls: "stun:stun.waterpolopalermo.it:3478",
      },
      {
        urls: "stun:stun.uls.co.za:3478",
      },
      {
        urls: "stun:stun.deepfinesse.com:3478",
      },
      {
        urls: "stun:stun.voztele.com:3478",
      },
      {
        urls: "stun:stun.ru-brides.com:3478",
      },
      {
        urls: "stun:stun.lineaencasa.com:3478",
      },
      {
        urls: "stun:stun.voippro.com:3478",
      },
      {
        urls: "stun:stun.gmx.de:3478",
      },
      {
        urls: "stun:stun.technosens.fr:3478",
      },
      {
        urls: "stun:stun.swrag.de:3478",
      },
      {
        urls: "stun:stun.tel.lu:3478",
      },
      {
        urls: "stun:stun.lundimatin.fr:3478",
      },
      {
        urls: "stun:stun.myvoipapp.com:3478",
      },
      {
        urls: "stun:stun.galeriemagnet.at:3478",
      },
      {
        urls: "stun:stun.ppdi.com:3478",
      },
      {
        urls: "stun:stun.levigo.de:3478",
      },
      {
        urls: "stun:stun.fbsbx.com:3478",
      },
      {
        urls: "stun:stun.piratenbrandenburg.de:3478",
      },
      {
        urls: "stun:stun.soho66.co.uk:3478",
      },
      {
        urls: "stun:stun.taxsee.com:3478",
      },
      {
        urls: "stun:stun.goldfish.ie:3478",
      },
      {
        urls: "stun:stun.fitauto.ru:3478",
      },
      {
        urls: "stun:stun.yesdates.com:3478",
      },
      {
        urls: "stun:stun.siptraffic.com:3478",
      },
      {
        urls: "stun:stun.acronis.com:3478",
      },
      {
        urls: "stun:stun.hosteurope.de:3478",
      },
      {
        urls: "stun:stun.heeds.eu:3478",
      },
      {
        urls: "stun:stun.freeswitch.org:3478",
      },
      {
        urls: "stun:stun.bernardoprovenzano.net:3478",
      },
      {
        urls: "stun:stun.alpirsbacher.de:3478",
      },
      {
        urls: "stun:stun.eleusi.com:3478",
      },
      {
        urls: "stun:stun.axeos.nl:3478",
      },
      {
        urls: "stun:stun.imafex.sk:3478",
      },
      {
        urls: "stun:stun.anlx.net:3478",
      },
      {
        urls: "stun:stun.kaseya.com:3478",
      },
      {
        urls: "stun:stun.openvoip.it:3478",
      },
      {
        urls: "stun:stun.dls.net:3478",
      },
      {
        urls: "stun:stun.netappel.com:3478",
      },
      {
        urls: "stun:stun.ringvoz.com:3478",
      },
      {
        urls: "stun:stun.teamfon.de:3478",
      },
      {
        urls: "stun:stun.l.google.com:19305",
      },
      {
        urls: "stun:stun.optdyn.com:3478",
      },
      {
        urls: "stun:stun2.l.google.com:19302",
      },
      {
        urls: "stun:stun.infra.net:3478",
      },
      {
        urls: "stun:stun.redworks.nl:3478",
      },
      {
        urls: "stun:stun.nonoh.net:3478",
      },
      {
        urls: "stun:stun.m-online.net:3478",
      },
      {
        urls: "stun:stun.logic.ky:3478",
      },
      {
        urls: "stun:stun.stochastix.de:3478",
      },
      {
        urls: "stun:stun.easyvoip.com:3478",
      },
      {
        urls: "stun:stun.peeters.com:3478",
      },
      {
        urls: "stun:stun.avigora.fr:3478",
      },
      {
        urls: "stun:stun.voipinfocenter.com:3478",
      },
      {
        urls: "stun:stun.wifirst.net:3478",
      },
      {
        urls: "stun:stun.sipgate.net:10000",
      },
      {
        urls: "stun:stun.3wayint.com:3478",
      },
      {
        urls: "stun:stun.voipplanet.nl:3478",
      },
      {
        urls: "stun:stun.webmatrix.com.br:3478",
      },
      {
        urls: "stun:stun.ippi.com:3478",
      },
      {
        urls: "stun:stun.rockenstein.de:3478",
      },
      {
        urls: "stun:stun.voipzoom.com:3478",
      },
      {
        urls: "stun:stun.voipconnect.com:3478",
      },
      {
        urls: "stun:stun.framasoft.org:3478",
      },
      {
        urls: "stun:stun.carlovizzini.it:3478",
      },
      {
        urls: "stun:stun.connecteddata.com:3478",
      },
      {
        urls: "stun:stun.acrobits.cz:3478",
      },
      {
        urls: "stun:stun.sip.us:3478",
      },
      {
        urls: "stun:stun.voipcheap.co.uk:3478",
      },
      {
        urls: "stun:stun.allflac.com:3478",
      },
      {
        urls: "stun:stun.var6.cn:3478",
      },
      {
        urls: "stun:stun.symonics.com:3478",
      },
      {
        urls: "stun:stun.ipfire.org:3478",
      },
      {
        urls: "stun:stun.romaaeterna.nl:3478",
      },
      {
        urls: "stun:stun1.l.google.com:19302",
      },
      {
        urls: "stun:stun.sigmavoip.com:3478",
      },
      {
        urls: "stun:stun.meowsbox.com:3478",
      },
      {
        urls: "stun:stun.shadrinsk.net:3478",
      },
      {
        urls: "stun:stun.powervoip.com:3478",
      },
      {
        urls: "stun:stun.godatenow.com:3478",
      },
      {
        urls: "stun:stun.vo.lu:3478",
      },
      {
        urls: "stun:stun.thebrassgroup.it:3478",
      },
      {
        urls: "stun:stun.voipraider.com:3478",
      },
      {
        urls: "stun:stun.1-voip.com:3478",
      },
      {
        urls: "stun:stun.nanocosmos.de:3478",
      },
      {
        urls: "stun:stun.usfamily.net:3478",
      },
      {
        urls: "stun:stun.muoversi.net:3478",
      },
      {
        urls: "stun:stun.freecall.com:3478",
      },
      {
        urls: "stun:stun.fathomvoice.com:3478",
      },
      {
        urls: "stun:stun.jay.net:3478",
      },
      {
        urls: "stun:stun.internetcalls.com:3478",
      },
      {
        urls: "stun:stun.voys.nl:3478",
      },
      {
        urls: "stun:iphone-stun.strato-iphone.de:3478",
      },
      {
        urls: "stun:stun.ozekiphone.com:3478",
      },
      {
        urls: "stun:stun.ipshka.com:3478",
      },
      {
        urls: "stun:stun4.l.google.com:19302",
      },
      {
        urls: "stun:stun.next-gen.ro:3478",
      },
      {
        urls: "stun:stun.leucotron.com.br:3478",
      },
      {
        urls: "stun:stun.autosystem.com:3478",
      },
      {
        urls: "stun:stun.voipstunt.com:3478",
      },
      {
        urls: "stun:stun.hoiio.com:3478",
      },
      {
        urls: "stun:stun.vivox.com:3478",
      },
      {
        urls: "stun:stun.pure-ip.com:3478",
      },
      {
        urls: "stun:stun.ttmath.org:3478",
      },
      {
        urls: "stun:stun.talkho.com:3478",
      },
      {
        urls: "stun:stun.nfon.net:3478",
      },
      {
        urls: "stun:stun.tula.nu:3478",
      },
      {
        urls: "stun:stun.bandyer.com:3478",
      },
      {
        urls: "stun:stun.bluesip.net:3478",
      },
      {
        urls: "stun:stun.tel2.co.uk:3478",
      },
      {
        urls: "stun:stun.hicare.net:3478",
      },
      {
        urls: "stun:stun.counterpath.net:3478",
      },
      {
        urls: "stun:stun.bergophor.de:3478",
      },
      {
        urls: "stun:stun.voip.blackberry.com:3478",
      },
      {
        urls: "stun:stun.mywatson.it:3478",
      },
      {
        urls: "stun:stun.dus.net:3478",
      },
      {
        urls: "stun:stun.studio71.it:3478",
      },
      {
        urls: "stun:stun.bridesbay.com:3478",
      },
      {
        urls: "stun:stun.openjobs.hu:3478",
      },
      {
        urls: "stun:stun.babelforce.com:3478",
      },
      {
        urls: "stun:stun.voicetech.se:3478",
      },
      {
        urls: "stun:stun.schoeffel.de:3478",
      },
      {
        urls: "stun:stun.baltmannsweiler.de:3478",
      },
      {
        urls: "stun:stun.geesthacht.de:3478",
      },
      {
        urls: "stun:stun.telbo.com:3478",
      },
      {
        urls: "stun:stun.sipdiscount.com:3478",
      },
      {
        urls: "stun:stun.vavadating.com:3478",
      },
      {
        urls: "stun:stun.eoni.com:3478",
      },
      {
        urls: "stun:stun.commpeak.com:3478",
      },
      {
        urls: "stun:stun.aaisp.co.uk:3478",
      },
      {
        urls: "stun:stun.wemag.com:3478",
      },
      {
        urls: "stun:stun.threema.ch:3478",
      },
      {
        urls: "stun:stun.qcol.net:3478",
      },
      {
        urls: "stun:stun.voicetrading.com:3478",
      },
      {
        urls: "stun:stun.beebeetle.com:3478",
      },
      {
        urls: "stun:stun.voztovoice.org:3478",
      },
      {
        urls: "stun:stun.verbo.be:3478",
      },
      {
        urls: "stun:stun.streamnow.ch:3478",
      },
      {
        urls: "stun:stun.voipcheap.com:3478",
      },
      {
        urls: "stun:stun.miwifi.com:3478",
      },
      {
        urls: "stun:stun.voipgain.com:3478",
      },
      {
        urls: "stun:stun.nextcloud.com:443",
      },
      {
        urls: "stun:stun.simlar.org:3478",
      },
      {
        urls: "stun:stun.planetarium.com.br:3478",
      },
      {
        urls: "stun:stun.funwithelectronics.com:3478",
      },
      {
        urls: "stun:stun2.l.google.com:19305",
      },
      {
        urls: "stun:stun.myspeciality.com:3478",
      },
      {
        urls: "stun:stun.fmo.de:3478",
      },
      {
        urls: "stun:stun.engineeredarts.co.uk:3478",
      },
      {
        urls: "stun:stun.dunyatelekom.com:3478",
      },
      {
        urls: "stun:stun.gravitel.ru:3478",
      },
      {
        urls: "stun:stun.tichiamo.it:3478",
      },
      {
        urls: "stun:stun.voipdiscount.com:3478",
      },
      {
        urls: "stun:stun.jowisoftware.de:3478",
      },
      {
        urls: "stun:stun.webcalldirect.com:3478",
      },
      {
        urls: "stun:stun.halonet.pl:3478",
      },
      {
        urls: "stun:stun.komsa.de:3478",
      },
      {
        urls: "stun:stun.graftlab.com:3478",
      },
      {
        urls: "stun:stun4.l.google.com:19305",
      },
      {
        urls: "stun:stun.freevoipdeal.com:3478",
      },
      {
        urls: "stun:stun.solcon.nl:3478",
      },
      {
        urls: "stun:stun.schlund.de:3478",
      },
      {
        urls: "stun:stun.leonde.org:3478",
      },
      {
        urls: "stun:stun.zadarma.com:3478",
      },
      {
        urls: "stun:stun.kitamaebune.com:3478",
      },
      {
        urls: "stun:stun.gmx.net:3478",
      },
      {
        urls: "stun:stun.wxnz.net:3478",
      },
      {
        urls: "stun:stun.5sn.com:3478",
      },
      {
        urls: "stun:stun.jabber.dk:3478",
      },
      {
        urls: "stun:stun.thinkrosystem.com:3478",
      },
      {
        urls: "stun:stun.olimontel.it:3478",
      },
      {
        urls: "stun:stun.demos.ru:3478",
      },
      {
        urls: "stun:stun.smslisto.com:3478",
      },
      {
        urls: "stun:stun.clickphone.ro:3478",
      },
      {
        urls: "stun:stun.vadacom.co.nz:3478",
      },
      {
        urls: "stun:stun.mit.de:3478",
      },
      {
        urls: "stun:stun.voipblast.com:3478",
      },
      {
        urls: "stun:stun.telonline.com:3478",
      },
      {
        urls: "stun:stun.signalwire.com:3478",
      },
      {
        urls: "stun:relay.webwormhole.io:3478",
      },
      {
        urls: "stun:stun.poivy.com:3478",
      },
      {
        urls: "stun:stun.syrex.co.za:3478",
      },
      {
        urls: "stun:stun.nautile.nc:3478",
      },
      {
        urls: "stun:stun.marcelproust.it:3478",
      },
      {
        urls: "stun:stun.h4v.eu:3478",
      },
      {
        urls: "stun:stun.lebendigefluesse.at:3478",
      },
      {
        urls: "stun:stun.westtel.ky:3478",
      },
      {
        urls: "stun:stun.megatel.si:3478",
      },
      {
        urls: "stun:stun.isp.net.au:3478",
      },
      {
        urls: "stun:stun.callromania.ro:3478",
      },
      {
        urls: "stun:stun.plexicomm.net:3478",
      },
      {
        urls: "stun:stun.siedle.com:3478",
      },
      {
        urls: "stun:stun.voipbusterpro.com:3478",
      },
      {
        urls: "stun:stun.irishvoip.com:3478",
      },
      {
        urls: "stun:stun.it1.hr:3478",
      },
      {
        urls: "stun:stun.siplogin.de:3478",
      },
      {
        urls: "stun:stun.sky.od.ua:3478",
      },
      {
        urls: "stun:stun.gntel.nl:3478",
      },
      {
        urls: "stun:stun.eol.co.nz:3478",
      },
      {
        urls: "stun:stun.voipwise.com:3478",
      },
      {
        urls: "stun:stun.3deluxe.de:3478",
      },
      {
        urls: "stun:stun.ixc.ua:3478",
      },
      {
        urls: "stun:stun.ooma.com:3478",
      },
      {
        urls: "stun:stun.linuxtrent.it:3478",
      },
      {
        urls: "stun:stun.bethesda.net:3478",
      },
      {
        urls: "stun:stun.wia.cz:3478",
      },
      {
        urls: "stun:stun.wcoil.com:3478",
      },
      {
        urls: "stun:stun.nexxtmobile.de:3478",
      },
      {
        urls: "stun:stun.root-1.de:3478",
      },
      {
        urls: "stun:stun.aa.net.uk:3478",
      },
      {
        urls: "stun:stun.voipgate.com:3478",
      },
      {
        urls: "stun:stun.liveo.fr:3478",
      },
      {
        urls: "stun:stun.ekiga.net:3478",
      },
      {
        urls: "stun:stun.fixup.net:3478",
      },
      {
        urls: "stun:stun.frozenmountain.com:3478",
      },
      {
        urls: "stun:stun.fairytel.at:3478",
      },
      {
        urls: "stun:stun.t-online.de:3478",
      },
      {
        urls: "stun:stun.justvoip.com:3478",
      },
      {
        urls: "stun:stun.expandable.io:3478",
      },
      {
        urls: "stun:stun.rynga.com:3478",
      },
      {
        urls: "stun:stun.hot-chilli.net:3478",
      },
      {
        urls: "stun:stun.otos.pl:3478",
      },
      {
        urls: "stun:stun.comrex.com:3478",
      },
      {
        urls: "stun:stun.radiojar.com:3478",
      },
      {
        urls: "stun:stun.localphone.com:3478",
      },
      {
        urls: "stun:stun.synergiejobs.be:3478",
      },
      {
        urls: "stun:stun.grazertrinkwasseringefahr.at:3478",
      },
      {
        urls: "stun:stun.voipxs.nl:3478",
      },
      {
        urls: "stun:stun.genymotion.com:3478",
      },
      {
        urls: "stun:stun.tng.de:3478",
      },
      {
        urls: "stun:stun.lowratevoip.com:3478",
      },
      {
        urls: "stun:stun.twt.it:3478",
      },
      {
        urls: "stun:stun.eurosys.be:3478",
      },
      {
        urls: "stun:stun.voipgrid.nl:3478",
      },
      {
        urls: "stun:stun.officinabit.com:3478",
      },
      {
        urls: "stun:stun.newrocktech.com:3478",
      },
      {
        urls: "stun:stun.gigaset.net:3478",
      },
      {
        urls: "stun:stun.bitburger.de:3478",
      },
      {
        urls: "stun:stun.voipfibre.com:3478",
      },
    ],
  },
};

export default config;
